<template>
  <v-container class="py-7">
    <div class="heading mb-7">Заказ №{{ id }} успешно оплачен</div>
  </v-container>
</template>

<script>
export default {
  computed: {
    id() {
      return this.$route.query?.id
    },
  },

  mounted() {
    // this.$tracardi.track('order-completed', {
    //   order: {
    //     id: this.id,
    //     status: 'completed',
    //   },
    // })
  },
}
</script>
